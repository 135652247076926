@tailwind base;
@tailwind components;
@tailwind utilities;

/* width */
::-webkit-scrollbar {
	height: 5px;
	width: 5px;
}

:root {
	scrollbar-width: 5px;
	scrollbar-color: #07a8bc;
	scrollbar-track-color: #f1f1f1;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-width: 375px;
}

body {
	font-family: var(--font-poppins);
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

.muted-scrollbar ::-webkit-scrollbar-track {
	background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #07a8bc;
	border-radius: 5px;
}

.muted-scrollbar ::-webkit-scrollbar-thumb {
	background: #eef1f1;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

@layer base {
	:root {
		--background: 0 0% 100%;
		--foreground: 0 0% 3.9%;

		--card: 0 0% 100%;
		--card-foreground: 0 0% 3.9%;

		--popover: 0 0% 100%;
		--popover-foreground: 0 0% 3.9%;

		--primary: 0 0% 100%;
		--primary-foreground: 0 0% 0%;

		--secondary: 187 93% 38%;
		--secondary-foreground: 0 0% 100%;

		--tertiary: 174 72% 56%;

		--muted: 0 0% 96.1%;
		--muted-foreground: 0 1% 53%;

		--accent: 0 0% 96.1%;
		--accent-foreground: 174 72% 56%;

		--grey: 0 0% 53%;
		--grey-foreground: 0 1% 53%;

		--destructive: 0 84.2% 60.2%;
		--destructive-foreground: 0 0% 98%;

		--border: 0 0% 89.8%;
		--input: 0 0% 89.8%;
		--ring: 0 0% 3.9%;

		--radius: 0.5rem;
	}

	.dark {
		--background: 0 0% 3.9%;
		--foreground: 0 0% 98%;

		--card: 0 0% 3.9%;
		--card-foreground: 0 0% 98%;

		--popover: 0 0% 3.9%;
		--popover-foreground: 0 0% 98%;

		--primary: 0 0% 98%;
		--primary-foreground: 0 0% 3%;

		--secondary: 187 96% 74%;
		--secondary-foreground: 0 0% 0%;

		--muted: 0 0% 14.9%;
		--muted-foreground: 0 0% 63.9%;

		--accent: 0 0% 14.9%;
		--accent-foreground: 174 72% 88%;

		--grey: 0 1% 53% 0.1;
		--grey-foreground: 0 0% 85%;

		--destructive: 0 62.8% 30.6%;
		--destructive-foreground: 0 0% 98%;

		--border: 0 0% 14.9%;
		--input: 0 0% 14.9%;
		--ring: 0 0% 83.1%;
	}
}

@layer base {
	* {
		@apply border-border;
	}
	body {
		@apply bg-background text-foreground;
	}

	[hidden] {
		display: none !important;
	}
}

@layer components {
	.carousel-container {
		@apply gap-x-8;
	}
}

@layer utilities {
	.grid-cols-3_1 {
		grid-template-columns: 3fr 1fr;
	}
	.grid-cols-4_3 {
		grid-template-columns: 4fr 3fr;
	}
	.grid-cols-2_5 {
		grid-template-columns: 2fr 5fr;
	}
	.grid-cols-1_6 {
		grid-template-columns: 1fr 6fr;
	}
	.grid-cols-1_2 {
		grid-template-columns: 1fr 2fr;
	}
	.grid-cols-1_4 {
		grid-template-columns: 1fr 4fr;
	}
	.hero-background {
		background-image: url("/public/assets/home/hero/backdrop.svg");
	}
	.light-blue-background {
		background: rgba(63, 224, 208, 0.1);
	}
	.light-pink-background {
		background-color: #fceded;
	}
	.light-yellow-background {
		background-color: #fff9ef;
	}
	.light-green-background {
		background-color: #f3fef1;
	}
	.footer-background {
		background: #070707;
	}
	.loading-background {
		background: linear-gradient(
			180deg,
			#07a8bc 42.71%,
			#3fe0d0 95.83%,
			#3fe0d0 98.96%,
			#3fe0d0 99.07%
		);
	}
	.loading-backdrop {
		background: black;
		opacity: 0.5;
	}

	/* loading animation */
	.pulse {
		animation: pulse-animation 2s infinite;
	}
	@keyframes pulse-animation {
		0% {
			opacity: 0.1;
		}
		50% {
			opacity: 1;
		}
		100% {
			opacity: 0.1;
		}
	}
	.custom-box-shadow {
		box-shadow:
			0 4px 6px -1px rgba(0, 0, 0, 0.25),
			0 2px 4px -1px rgba(0, 0, 0, 0.06);
	}

	.sliced-element {
		clip-path: polygon(0 0, calc(100% - 40px) 0, 100% 100%, 0 100%);
	}

	.custom-border-color {
		border-color: rgba(7, 168, 188, 0.2);
	}

	/* loading animation */
}

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Works for Firefox */
input[type="number"] {
	-moz-appearance: textfield;
} /* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Works for Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

/* animations */
@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translateY(20px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes zoomIn {
	from {
		transform: scale(1);
	}
	to {
		transform: scale(1.05);
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes slideDown {
	from {
		transform: translateY(-20px);
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
}

.animate-slideDown {
	animation: slideDown 0.5s ease-in-out;
}

@keyframes pageFadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.pageFadeIn {
	animation: pageFadeIn 0.3s ease-in-out;
}
/* Loading */
.loader {
	animation: spin 1s linear infinite;
}

/* Define the animation */
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
